.helpContainer {
  align-items: center;
  background-color: var(--color-base-white);
  border-radius: var(--border-radius-full);
  display: flex;
  justify-content: center;
  padding: 6px;
}

.helpContainer svg {
  background-color: rgba(4, 55, 122, 0.1);
  border-radius:  var(--border-radius-full);
  padding: 6px;
}

.customPosition {
  box-shadow: none;
  right: 150px;
  top: 23px;
}

.customPosition > div {
  box-shadow: var(--box-shadow-2xs);
}

.popupCorner{
	height: 25px;
	position: absolute;
	width: 25px;
  background-color: var(--color-base-white);
  border-bottom-color: var(--color-base-white);
  right: 88px;
  top: 66px;
  transform: rotate(45deg);
}

.main-header {
  background-color: var(--color-base-white);
  top: 0;
  transition: top 300ms ease-in-out;
  border-bottom: 0.3px solid var(--color-neutral-gray-divider);
}

.hide-menu {
  top: -74px;
}

.buttonHover {
  align-items: center;
  border: 1px solid var(--color-text-gray-light);
  display: flex;
  justify-content: center;
}

.buttonHover:hover {
  box-shadow: 0px 6px 10px rgba(27, 33, 56, 0.15);
}