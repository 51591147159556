.numberButton {
  background-color: var(--color-background-white);
  border: 1px solid var(--color-primary-disabled);
  color: var(--color-primary-disabled);
}

.numberButton:hover {
  background-color: var(--color-background-gray);
}

.numberButtonSelected {
  background-color: var(--color-background-white);
  border: 1px solid var(--color-neutral-black);
  color: var(--color-neutral-black);
}

.numberButtonSelected:hover {
  background-color: var(--color-background-white);
  border: 1px solid var(--color-neutral-black);
  color: var(--color-neutral-black);
}