.Subscription__membershipDays {
  white-space: nowrap;
}
.containerBoxShadow {
  box-shadow: var(--box-shadow-around);
}

.containerBoxShadowFeedback {
  box-shadow: var(--box-shadow-2xs);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.containerBoxShadowXs {
  box-shadow: var(--box-shadow-xs);
  margin: auto;
  padding: 20px 0px;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.container1Icon {
  align-items: center;
  background: rgba(73, 169, 49, 0.1);
  display: flex;
  height: 129px;
  justify-content: center;
  width: 129px;
}

.container2Icon {
  align-items: center;
  background: rgba(27, 137, 0, 0.1);
  display: flex;
  justify-content: center;
}

.iconError {
  align-items: center;
  background: linear-gradient(180deg, #da291c 0%, #c00000 44.44%);
  border-radius: 50px;
  box-shadow: 0px 4px 8px rgba(27, 33, 56, 0.06);
  display: flex;
  height: 60px;
  justify-content: center;
  width: 60px;
}

.containerIconArrowOpen {
  transform: rotate(-90deg);
}

.containerIconArrowDown {
  transform: rotate(90deg);
}

.repeatPurchaseBtn {
  background: var(--color-primary-red2);
}

.repeatPurchaseBtn:hover {
  background: var(--color-primary-red-dark);
}

@media screen and (max-width: 79.9375em) {
  .containerMinHeight {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: hidden;
  }

  .containerMinHeightWithBack {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 158px);
  }
}

.letterWeight {
  font-weight: 500;
}
