.icon--padding {
    padding-right: 35px;
    padding-top: 7px;
}

.changeOrder {
    order: 2;
}

.switch--toggle {
    width: 280px;
    height: 35px !important;
}

.switch--toggle-text {
    text-align: center;
    padding: 0 30px !important;
}

@media screen and (max-width: 768px) {
    .switch--toggle {
        width: 343px;
        height: 35px !important;
    }

    .switch--toggle-text {
        text-align: center;
        padding: 0 50px !important;
    }
}