.select__container {
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  justify-content: space-between;
  line-height: 19px;
  position: relative;
  width: 100%;
}

.select__filter {
  appearance: none;
  border: none;
  color: var(--color-neutral-black);
  display: flex;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.12px;
  width: 100%;
}

.select__filter span {
  padding-right: 10px;
  width: 112px;
}

.select__filter::-ms-expand {
  display: none;
}

.select__filter:focus-visible {
  outline: 0;
}

.select__filter--mobile {
  margin-top: 2px;
  background-color: var(--color-base-white);
}

.select__icon {
  pointer-events: none;
  right: 5px;
}

.select__list {
  align-items: flex-start;
  background-color: var(--color-base-white);
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(27, 33, 56, 0.06);
  display: flex;
  flex-direction: column;
  gap: 80px;
  left: -93px;
  max-width: 216px;
  position: absolute;
  padding: 8px 12px 8px 8px;
  top: 100%;
  height: 94px;
  z-index: var(--z-index-1);
}

.select__list--bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: inherit;
  top: inherit;
}

.select__list ul {
  list-style: none;
}

.select__list ul li:nth-child(2n) {
  margin-top: 8px;
}

.select__list ul li {
  align-items: flex-start;
  font-size: 14px;
  font-weight: 400;
  justify-content: center;
  line-height: 19px;
  min-width: 196px;
  padding: 8px;
  height: 35px;
}

.select__options {
  padding: 10px;
}

.select__options--selected {
  background-color: var(--color-neutral-gray-light);
}
