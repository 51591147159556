@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('/fonts/PlusJakartaSans-VariableFont_wght.ttf');
  font-display: block;
  font-style: normal;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family:
    'Plus Jakarta Sans',
    sans-serif,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.no-scroll {
  overflow: hidden !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

/* Remove selection highlighting on touch devices */
@media (hover: none) {
  /* Safari, Chrome and browsers which are based on WebKit */
  body {
    -webkit-tap-highlight-color: transparent;
  }

  /* Firefox */
  body:active {
    background-color: transparent;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: '--font-size-base';
  }
}

@media (width <= 39.9375em) {
  :root {
    --font-weight-semibold: 500 !important;
    --font-weight-bold: 500 !important;
  }
}
